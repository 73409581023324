/* eslint-disable max-lines */
import { useEffect, useMemo, useState } from 'react';
import { Button, CircularProgress, Paper, TextField } from '@mui/material';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAuth } from '../components/auth/AuthProvider';
import { schemaSummary } from '../components/validation/schemaSummary';
import SummaryTextFieldAccordion from '../components/summary/SummaryTextFieldAccordion';
import SummaryTextFieldAccordionTo from '../components/summary/SummaryTextFieldAccordionTo';
import { parseExternalEvents } from '../components/summary/utils/parseExternalEvents';
import { getSummaryData } from '../api/fetchSummary';
import { saveSummary } from '../api/saveSummary';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast, ToastContainer } from 'react-toastify';
import { ISummaryData, ISummaryFormData } from '../interface/summary';
import { getMainProduct } from '../api/getMainProduct';
import { SkeletonForm } from '../components/commons/Skeleton';
import { sendSummary } from '../api/sendSummary';
import ConfirmModal from '../components/commons/ConfirmModal';
import { TimePicker } from '@mui/x-date-pickers';
import moment from 'moment';

const summaryDefaultValues = {
  qt_ricevuti: 0,
  qt_distribuiti_digitale: 0,
  qt_distribuiti_non_registrati: 0,
  qt_distribuiti: 0,
  qt_non_utilizzabili: 0,
  qt_rimanenze: 0,
  qt_contributi_digitale: 0,
  qt_contributi_non_registrati: 0,
  tot_contributi: 0,
  qt_ricevute_cartacee: 0,
  qt_ricevute_digitali: 0,
  qt_richiesta_anno_successivo: 0,
  qt_piazze_esterne: [{ nome: '', quantità: '' }],
  qt_date_piazze_esterne: [{ nome: '', quantità: '' }],
  commenti: '',
  orario_inizio: new Date(),
  orario_fine: new Date(),
};

export const RendicontazioneSummary = () => {
  const [confirmOpen, setConfirmOpen] = useState(false);
  const { handleSubmit, control, reset, getValues, setValue } = useForm<ISummaryFormData>({
    resolver: yupResolver(schemaSummary),
    defaultValues: summaryDefaultValues,
  });

  const useFormValues = () => {
    return {
      ...useWatch({ control }), // subscribe to form value updates
      ...getValues(), // always merge with latest form values
    };
  };

  const { token } = useAuth();
  const queryClient = useQueryClient();

  const { data: product, isLoading: isLoadingProduct } = useQuery(['main-product'], () => getMainProduct(token), {
    refetchOnWindowFocus: false,
    staleTime: 30000,
  });

  const { data: rendicontazione, isFetching: isLoadingSummary } = useQuery(
    ['summary-data'],
    () => getSummaryData(token),
    {
      refetchOnWindowFocus: false,
      staleTime: 1000,
      retry: 0,
      onSuccess: (data) => {
        reset(data as any);
      },
    },
  );

  const { mutate: mutateCreate, isLoading } = useMutation<ISummaryData, Error, ISummaryData>((data: ISummaryData) => {
    return saveSummary(data, token);
  });

  const {
    qt_distribuiti_non_registrati,
    qt_contributi_non_registrati,
    qt_distribuiti_digitale,
    qt_contributi_digitale,
  } = useFormValues();

  useEffect(() => {
    const sommaDistribuiti = Number(qt_distribuiti_digitale || 0) + Number(qt_distribuiti_non_registrati || 0);
    const sommaContributi = Number(qt_contributi_digitale || 0) + Number(qt_contributi_non_registrati || 0);

    setValue('qt_distribuiti', sommaDistribuiti);
    setValue('tot_contributi', sommaContributi);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qt_contributi_non_registrati, qt_distribuiti_non_registrati]);

  const mainProduct = useMemo(() => {
    if (product) {
      return product.nome.split(' ')[0];
    } else {
      return 'Prodotti';
    }
  }, [product]);

  const setWordGender = (word: string) => {
    if (mainProduct?.endsWith('e')) {
      return word.substring(0, word.length - 1) + 'e';
    } else {
      return word;
    }
  };

  const closedEvent = !!rendicontazione?.sent;

  const onSubmit = (summaryFormdata: ISummaryFormData) => {
    const { qt_piazze_esterne, qt_date_piazze_esterne } = summaryFormdata;
    const parsedExternalEvents = parseExternalEvents(qt_piazze_esterne);
    const parsedExternalEventsTo = parseExternalEvents(qt_date_piazze_esterne);

    const data = {
      ...summaryFormdata,
      qt_piazze_esterne: parsedExternalEvents,
      qt_date_piazze_esterne: parsedExternalEventsTo,
    };

    delete data.qt_distribuiti_digitale;
    delete data.qt_distribuiti_non_registrati;
    delete data.qt_contributi_digitale;
    delete data.qt_contributi_non_registrati;

    mutateCreate(data, {
      onSuccess: () => {
        toast.success('La rendicontazione è salvata in bozza! Puoi modificarla quando vuoi.');
      },
      onError: (error: Error) => {
        toast.error(error.message);
      },
    });
  };

  const checkTotalQuantity = (values: ISummaryFormData) => {
    const {
      qt_ricevuti,
      qt_piazze_esterne,
      qt_distribuiti,
      qt_non_utilizzabili,
      qt_rimanenze,
      qt_date_piazze_esterne,
    } = values;

    const piazzeEsterneTotalIn = qt_piazze_esterne.reduce((acc, curr) => acc + Number(curr.quantità), 0);
    const countIn = qt_ricevuti + piazzeEsterneTotalIn;

    const piazzeEsterneTotalOut = qt_date_piazze_esterne.reduce((acc, curr) => acc + Number(curr.quantità), 0);
    const countOut = qt_non_utilizzabili + qt_distribuiti + qt_rimanenze + piazzeEsterneTotalOut;

    return countIn === countOut;
  };

  const onCloseEvent = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setConfirmOpen(true);
  };

  const handleCloseEvent = async () => {
    try {
      const formValues = getValues();
      if (!checkTotalQuantity(formValues)) {
        toast.error('Il totale delle quantità in ingresso non corrisponde al totale delle quantità in uscita!');
        return;
      }
      await sendSummary(token);
      toast.success("Hai chiuso l'evento! La rendicontazione sarà inviata agli uffici territoriali.");
      queryClient.invalidateQueries({ queryKey: ['summary-data'] });
      setConfirmOpen(false);
    } catch (error: any) {
      toast.error(error.message);
    }
  };

  if (isLoadingProduct || isLoadingSummary) {
    return <SkeletonForm />;
  }

  return (
    <div className="container-main">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <ToastContainer />
      <h1>Rendicontazione Allegato B</h1>

      {closedEvent && (
        <center>
          <i>
            <small>Evento chiuso non modificabile</small>
          </i>
        </center>
      )}

      <form onSubmit={handleSubmit(onSubmit)}>
        <div
          className="person-card-box"
          style={{
            backgroundColor: '#ffffff',
            padding: 10,
            borderRadius: 8,
            justifyContent: 'center',
            textAlign: 'center',
          }}
        >
          <i>
            <small>Prodotto</small>
          </i>
          <Controller
            name="qt_ricevuti"
            control={control}
            disabled={closedEvent}
            render={({ field, fieldState: { error } }) => (
              <TextField
                size="small"
                label={`${mainProduct} ${setWordGender('Ricevuti')}`}
                {...field}
                error={!!error}
                type="number"
              />
            )}
          />
          <Paper
            variant="outlined"
            style={{
              textAlign: 'center',
              height: '150px',
              padding: 10,
            }}
          >
            <span>
              <small>{`${mainProduct} ${setWordGender('Distribuiti')}`}</small>
            </span>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: 10,
                alignItems: 'center',
                height: '100%',
              }}
            >
              <Controller
                name="qt_distribuiti_digitale"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    disabled
                    size="small"
                    sx={{
                      input: {
                        textAlign: 'center',
                        fontWeight: '900',
                      },
                      '& .MuiInputBase-input.Mui-disabled': {
                        WebkitTextFillColor: 'black',
                      },
                    }}
                    FormHelperTextProps={{
                      style: {
                        textAlign: 'center',
                        fontSize: '10px',
                        margin: 0,
                        marginTop: '3px',
                        lineHeight: '0.8rem',
                      },
                    }}
                    {...field}
                    error={!!error}
                    type="number"
                    variant="standard"
                    helperText="con incasso digitale"
                  />
                )}
              />
              <span>+</span>
              <Controller
                name="qt_distribuiti_non_registrati"
                control={control}
                disabled={closedEvent}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    size="small"
                    sx={{
                      input: {
                        textAlign: 'center',
                      },
                    }}
                    FormHelperTextProps={{
                      style: {
                        textAlign: 'center',
                        fontSize: '10px',
                        margin: 0,
                        marginTop: '3px',
                        color: 'rgba(0, 0, 0, 0.38)',
                        lineHeight: '0.8rem',
                      },
                    }}
                    helperText={error?.message ? error?.message : 'con donazione non registrata a donandi'}
                    {...field}
                    error={!!error}
                    type="number"
                  />
                )}
              />
              <span>=</span>
              <Controller
                name="qt_distribuiti"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    disabled
                    sx={{
                      input: {
                        textAlign: 'center',
                        fontWeight: '900',
                      },
                      '& .MuiInputBase-input.Mui-disabled': {
                        WebkitTextFillColor: '#00ACDE',
                      },
                    }}
                    FormHelperTextProps={{
                      style: {
                        textAlign: 'center',
                        fontSize: '10px',
                        margin: 0,
                        marginTop: '3px',
                        lineHeight: '0.8rem',
                      },
                    }}
                    size="small"
                    {...field}
                    error={!!error}
                    helperText="totale"
                    type="number"
                    variant="standard"
                  />
                )}
              />
            </div>
          </Paper>

          <Controller
            name="qt_non_utilizzabili"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                disabled
                size="small"
                label={`${mainProduct} Non Utilizzabili`}
                {...field}
                error={!!error}
                helperText={error?.message}
                type="number"
              />
            )}
          />
          <Controller
            name="qt_rimanenze"
            control={control}
            disabled={closedEvent}
            render={({ field, fieldState: { error } }) => (
              <TextField
                size="small"
                label="Rimanenze"
                {...field}
                error={!!error}
                helperText={error?.message}
                type="number"
              />
            )}
          />
          <SummaryTextFieldAccordion
            accordionSummaryTitle={`${mainProduct} ${setWordGender('Presi')} da altre piazze`}
            control={control}
            disabled={closedEvent}
          />
          <SummaryTextFieldAccordionTo
            accordionSummaryTitle={`${mainProduct} ${setWordGender('Dati')} ad altre piazze`}
            control={control}
            disabled={closedEvent}
          />
          <Controller
            name="qt_richiesta_anno_successivo"
            control={control}
            disabled={closedEvent}
            render={({ field, fieldState: { error } }) => (
              <TextField
                size="small"
                label={`${mainProduct} ${setWordGender('Richiesti')} per Il ${new Date().getFullYear() + 1}`}
                {...field}
                error={!!error}
                helperText={error?.message}
                type="number"
              />
            )}
          />
        </div>
        <div
          className="person-card-box"
          style={{
            backgroundColor: '#ffffff',
            padding: 10,
            borderRadius: 8,
            textAlign: 'center',
            height: '150px',
          }}
        >
          <i>
            <small>Contributi raccolti</small>
          </i>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: 10,
              alignItems: 'center',
            }}
          >
            <Controller
              name="qt_contributi_digitale"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  disabled
                  size="small"
                  sx={{
                    input: {
                      textAlign: 'center',
                      fontWeight: '900',
                    },
                    '& .MuiInputBase-input.Mui-disabled': {
                      WebkitTextFillColor: 'black',
                    },
                  }}
                  FormHelperTextProps={{
                    style: {
                      textAlign: 'center',
                      fontSize: '10px',
                      margin: 0,
                      marginTop: '3px',
                      lineHeight: '0.8rem',
                    },
                  }}
                  {...field}
                  error={!!error}
                  type="number"
                  variant="standard"
                  helperText="con incasso digitale"
                />
              )}
            />
            <span>+</span>
            <Controller
              name="qt_contributi_non_registrati"
              control={control}
              disabled={closedEvent}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  size="small"
                  {...field}
                  error={!!error}
                  sx={{
                    input: {
                      textAlign: 'center',
                    },
                  }}
                  FormHelperTextProps={{
                    style: {
                      textAlign: 'center',
                      fontSize: '10px',
                      margin: 0,
                      marginTop: '3px',
                      color: 'rgba(0, 0, 0, 0.38)',
                      lineHeight: '0.8rem',
                    },
                  }}
                  helperText={error?.message ? error?.message : 'con donazione non registrata a donandi'}
                  type="number"
                />
              )}
            />
            <span>=</span>
            <Controller
              name="tot_contributi"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  disabled
                  sx={{
                    input: {
                      textAlign: 'center',
                      fontWeight: '900',
                    },
                    '& .MuiInputBase-input.Mui-disabled': {
                      WebkitTextFillColor: '#00ACDE',
                    },
                  }}
                  FormHelperTextProps={{
                    style: {
                      textAlign: 'center',
                      fontSize: '10px',
                      margin: 0,
                    },
                  }}
                  size="small"
                  type="number"
                  {...field}
                  error={!!error}
                  helperText="totale"
                  variant="standard"
                />
              )}
            />
          </div>
        </div>
        <div
          className="person-card-box"
          style={{
            backgroundColor: '#ffffff',
            padding: 10,
            borderRadius: 8,
            justifyContent: 'center',
            textAlign: 'center',
          }}
        >
          <i>
            <small>Ricevute</small>
          </i>
          <Controller
            name="qt_ricevute_cartacee"
            control={control}
            disabled={closedEvent}
            render={({ field, fieldState: { error } }) => (
              <TextField
                size="small"
                label="Ricevute Cartacee Utilizzate"
                {...field}
                error={!!error}
                helperText={error?.message}
                type="number"
              />
            )}
          />
          <Controller
            name="qt_ricevute_digitali"
            control={control}
            disabled={closedEvent}
            render={({ field, fieldState: { error } }) => (
              <TextField
                size="small"
                label="Ricevute Digitali Utilizzate"
                {...field}
                error={!!error}
                helperText={error?.message}
                type="number"
              />
            )}
          />
        </div>
        <div
          className="person-card-box"
          style={{
            backgroundColor: '#ffffff',
            padding: 10,
            borderRadius: 8,
            justifyContent: 'center',
            textAlign: 'center',
          }}
        >
          <i>
            <small>Evento</small>
          </i>
          <Controller
            name="commenti"
            control={control}
            disabled={closedEvent}
            render={({ field, fieldState: { error } }) => (
              <TextField
                multiline
                minRows={2}
                size="small"
                label="Commenti..."
                {...field}
                error={!!error}
                helperText={error?.message}
              />
            )}
          />
          <Controller
            name="orario_inizio"
            control={control}
            disabled={closedEvent}
            render={({ field, fieldState: { error } }) => (
              <TimePicker
                label="Ora inizio distribuzione *"
                format="HH:mm"
                ampm={false}
                {...field}
                value={moment(field.value)}
              />
            )}
          />
          <Controller
            name="orario_fine"
            control={control}
            disabled={closedEvent}
            render={({ field, fieldState: { error } }) => (
              <TimePicker
                label="Ora fine distribuzione *"
                format="HH:mm"
                ampm={false}
                timezone="Europe/Rome"
                {...field}
                value={moment(field.value)}
              />
            )}
          />
        </div>
        {closedEvent && (
          <center>
            <i>
              <small>Evento chiuso non modificabile</small>
            </i>
          </center>
        )}

        <Button
          variant="contained"
          type="submit"
          disabled={isLoading || closedEvent}
          startIcon={isLoading && <CircularProgress size={16} color={'inherit'} />}
        >
          Salva
        </Button>
        <Button
          variant="outlined"
          type="button"
          disabled={isLoading || closedEvent || !rendicontazione}
          style={{ marginBottom: 20 }}
          startIcon={isLoading && <CircularProgress size={16} color={'inherit'} />}
          onClick={onCloseEvent}
        >
          Chiudi evento
        </Button>
      </form>

      <ConfirmModal
        open={confirmOpen}
        title="Vuoi chiudere l'evento?"
        text="Chiudendo l'evento la rendicontazione sarà inviata agli uffici territoriali. Se chiudi l'evento non potrai riaprirlo."
        confirmButtonText="Chiudi evento"
        backButtonText="Annulla"
        onCancel={() => setConfirmOpen(false)}
        onConfirm={() => handleCloseEvent()}
      />
    </div>
  );
};
